<template>
  <nav>
    <router-link to="/" exact-active-class="active-link">HOME</router-link>
    <router-link to="/analysen" exact-active-class="active-link"
      >Bildanalysenbeschreibung
    </router-link>
    <router-link to="/about" exact-active-class="active-link"
      >About
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped lang="scss">
nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: #3b4395;
  height: 7vh;
  align-items: center;
}

nav > a,
.router-link-active {
  color: #fbba00;
  font-weight: bold;
}

nav > a:hover {
  color: #ffdd57;
  font-weight: bold;
}

.active-link {
  color: #f3f3f3; /* This will be applied to the exact active route */
}
</style>
