<template>
  <NavBar />
  <div class="view-body">
    <div class="introduction-text">
      <h1>
        Willkommen beim Forensics-Tool, dem interaktiven Tool zum Erkennen von
        bearbeiteten Bildern
      </h1>
      <p>
        Wähle aus, ob du ein
        <a style="color: #e71372; font-family: Basic-Semibold; cursor: text"
          >eigenes bearbeitetes Bild hochladen</a
        >
        willst, oder ob du den
        <a style="color: #00a7e7; font-family: Basic-Semibold; cursor: text"
          >Quizmodus</a
        >
        spielen möchtest.
      </p>
    </div>
    <div class="selection-items">
      <img
        src="../assets/images/standing-23.svg"
        alt="figure-standing"
        class="fig-img"
      />
      <button class="selection-item upload" @click="goToUpload">
        <span class="button-text">Eigenes Bild hochladen und analysieren</span>
        <i class="fas fa-file-upload button-icon"></i>
      </button>
      <button class="selection-item preset" @click="goToSettings">
        <span class="button-text">Quizmodus</span>
        <i class="fas fa-laptop-code button-icon"></i>
      </button>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";

export default {
  components: {
    NavBar,
  },

  data() {
    return {};
  },
  methods: {
    goToSettings() {
      this.$router.push("/settings");
    },
    goToUpload() {
      this.$router.push("/upload");
    },
  },
};
</script>

<style scoped lang="scss">
.view-body {
  width: 100vw;
  height: auto;
}

.introduction-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  text-align: center;
}

.introduction-text > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #40434e;
  padding: 15px;
}

.introduction-text > p {
  font-size: 1.2rem;
  font-weight: 500;
  color: #40434e;
  padding: 15px;
}

.selection-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 10%;
  margin-left: -20vw;
  margin-top: -10vh;
}

.selection-item {
  width: 200px;
  height: 200px;
  border-radius: 30px;
  background-color: #f7c4a5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  color: black;
  border: none;
  cursor: pointer;
}

.upload,
.preset {
  font-size: 1em;
  font-family: Basic-SemiBold;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
}

.upload {
  background-color: #e71372;
  color: white;
}

.preset {
  background-color: #00a7e7;
  color: white;
}

.preset:hover,
.upload:hover {
  transform: scale(1.1);
}

.button-icon {
  font-size: 2em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .selection-items {
    flex-direction: column;
    margin-left: 0;
    margin-top: -55%;
    gap: 15px;
  }

  .selection-item {
    margin: 10px;
    height: 60px;
    border-radius: 5px;
  }

  .introduction-text > h1 {
    font-size: 1.5rem;
    padding: 15px;
  }

  .introduction-text > p {
    font-size: 0.9rem;
    padding: 15px;
  }

  .fig-img {
    display: none;
  }

  .button-icon {
    font-size: 1.5em;
  }
}

.button-text {
  font-size: 1em;
  padding: 5px;
}
</style>
