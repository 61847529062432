import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@fortawesome/fontawesome-free/css/all.css";
import VueTooltip from "v-tooltip";
//import "./assets/css/output.css";
//import "tailwindcss/tailwind.css";

createApp(App).use(store).use(router).use(VueTooltip).mount("#app");
