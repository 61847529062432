import { createStore } from "vuex";
import axios from "axios";
import analysis from "@/store/modules/analysis";

const apitest = {
  state: {},
  mutations: {},
  actions: {
    async testApi() {
      try {
        const response = await axios.post(
          "http://localhost:5000/dummy",
          {
            path: "biker.jpg",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  getters: {},
};

const leaderboard = {
  state: {
    leaderboard: [
      {
        name: "Max",
        points: 21,
      },
      {
        name: "Walter",
        points: 17,
      },
      {
        name: "Maria",
        points: 15,
      },
      {
        name: "Sebastian",
        points: 14,
      },
      {
        name: "Fiona",
        points: 13,
      },
      {
        name: "Sabine",
        points: 9,
      },
    ],
  },
  getters: {
    getTopFive: (state) => {
      state.leaderboard.sort((a, b) => {
        return b.points - a.points;
      });
      return state.leaderboard.slice(0, 5);
    },
  },
  mutations: {
    addEntry(state, entry) {
      state.leaderboard.push(entry);
    },
  },
  actions: {
    addEntry({ commit }, entry) {
      commit("addEntry", entry);
    },
  },
};

export default createStore({
  state: {
    API_URL: "https://five-be.neuronalresearch.media.fhstp.ac.at",
    API_IMAGES_PATH: "../../../../forensicapi/forensicapi/data/",
    IMAGE_FOLDER_PATH: "/data/",
    settings: {
      fälschungenErkennen: false,
      differenzenAnzeigen: false,
      totalRounds: 7,
    },
    score: {
      currentPoints: 0,
      advancedMode: false,
    },
    currentRound: 1,
    questionImages: [
      {
        id: 1,
        image: "img_1-min.jpg",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 3,
        image: "img_4_edited-min.jpg",
        isReal: false,
        partnerImageId: 4,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [4, 5, 6, 8, 9, 10, 12, 13, 14],
      },

      {
        id: 5,
        image: "img_2-min.jpg",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 7,
        image: "img_3-min.jpg",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 9,
        image: "img_5_edited-min.jpg",
        isReal: false,
        partnerImageId: 10,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [5, 6, 9, 13, 21, 22],
      },
      {
        id: 11,
        image: "img_6_edited-min.jpg",
        isReal: false,
        partnerImageId: 12,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [1, 2, 5, 6, 9, 10, 13, 14, 17],
      },
      {
        id: 13,
        image: "img_7-min.jpg",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 4,
        image: "img_4-min.jpg",
        isReal: true,
        partnerImageId: 3,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 10,
        image: "img_5-min.jpg",
        isReal: true,
        partnerImageId: 9,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 12,
        image: "img_6-min.jpg",
        isReal: true,
        partnerImageId: 11,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 35,
        image: "img_8-min.png",
        isReal: true,
        partnerImageId: 36,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 36,
        image: "img_8_edited-min.png",
        isReal: false,
        partnerImageId: 35,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23,
        ],
      },
      {
        id: 15,
        image: "img_9_edited-min.png",
        isReal: false,
        partnerImageId: 16,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [5, 6, 9, 10, 13, 14, 15, 17, 19, 21, 22],
      },
      {
        id: 16,
        image: "img_9-min.png",
        isReal: true,
        partnerImageId: 15,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 17,
        image: "img_10-min.png",
        isReal: true,
        partnerImageId: 18,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 18,
        image: "img_10_edited-min.png",
        isReal: false,
        partnerImageId: 17,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23,
        ],
      },
      {
        id: 19,
        image: "img_11-min.png",
        isReal: true,
        partnerImageId: 20,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 20,
        image: "img_11_edited-min.png",
        isReal: false,
        partnerImageId: 19,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [9, 10, 13, 14, 17, 18],
      },
      {
        id: 21,
        image: "img_12-min.png",
        isReal: true,
        partnerImageId: 22,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 22,
        image: "img_12_edited-min.png",
        isReal: false,
        partnerImageId: 21,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [9, 10, 17, 18, 21, 22],
      },
      {
        id: 23,
        image: "img_13-min.png",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 24,
        image: "img_14-min.png",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 25,
        image: "img_15.png",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 26,
        image: "img_16.png",
        isReal: true,
        partnerImageId: 27,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 27,
        image: "img_16_edited.png",
        isReal: false,
        partnerImageId: 26,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [6, 9, 10, 12, 13, 14, 16, 17, 18],
      },
      {
        id: 28,
        image: "img_17_edited.png",
        isReal: false,
        partnerImageId: 29,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [4, 5, 8, 9, 10, 12, 13, 14, 16, 17],
      },
      {
        id: 29,
        image: "img_17.png",
        isReal: true,
        partnerImageId: 28,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 30,
        image: "img_18.png",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 31,
        image: "img_19.png",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 32,
        image: "img_20.png",
        isReal: true,
        partnerImageId: 99,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
      {
        id: 33,
        image: "img_21_edited.png",
        isReal: false,
        partnerImageId: 34,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [4, 9, 10, 11],
      },
      {
        id: 34,
        image: "img_21.png",
        isReal: true,
        partnerImageId: 33,
        answered: false,
        answeredCorrectly: false,
        fakedBlocks: [],
      },
    ],
  },
  getters: {
    getHostURL: (state) => state.API_URL,
    getAPIImagesPath: (state) => state.API_IMAGES_PATH,
    getImageFolderPath: (state) => state.IMAGE_FOLDER_PATH,
    getSettings: (state) => state.settings,
    getScore: (state) => state.score,
    getQuestionImages: (state) => state.questionImages,
    getQuestionImagesRandomOrder: (state) => {
      let partnerImages = new Set(); // Use a Set for efficient lookup
      let randomImages = [];
      // Create a copy of questionImages and apply the Fisher-Yates shuffle
      let shuffledImages = [...state.questionImages];
      for (let i = shuffledImages.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [shuffledImages[i], shuffledImages[j]] = [
          shuffledImages[j],
          shuffledImages[i],
        ]; // Swap
      }
      // Iterate through shuffled images
      shuffledImages.forEach((image) => {
        if (
          !partnerImages.has(image.id) &&
          !partnerImages.has(image.partnerImageId)
        ) {
          // If neither the image nor its partner has been added, proceed
          partnerImages.add(image.id);
          partnerImages.add(image.partnerImageId); // Prevent both images of a pair from being added
          randomImages.push(image);
        }
      });
      return randomImages;
    },

    getFakedBlocks: (state) => (id) => {
      return state.questionImages.find((image) => image.id === id).fakedBlocks;
    },
    getPartnerImage: (state) => (id) => {
      //find the partner image with the given id
      const partnerImage = state.questionImages.find(
        (image) => image.id === id
      ).partnerImageId;
      //return the url of the partner image
      return state.questionImages.find((image) => image.id === partnerImage);
    },
    getImageByImageString: (state) => (imageString) => {
      return state.questionImages.find((image) => image.image === imageString);
    },
    //get Image by id and return the url
    getImage: (state) => (id) => {
      return state.questionImages.find((image) => image.id === id);
    },
    getCurrentRound: (state) => state.currentRound,
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
    setScore(state, score) {
      state.score = score;
    },
    addPoints(state, points) {
      state.score.currentPoints += points;
    },
    setNextRound(state) {
      state.currentRound++;
    },
    setAnswered(state, id) {
      state.questionImages.find((image) => image.id === id).answered = true;
    },
    addImage(state, image) {
      state.questionImages.push(image);
    },
  },
  actions: {
    setNextRound({ commit, state }) {
      if (state.currentRound < state.settings.totalRounds) {
        commit("setNextRound");
      }
    },
    setAnswered({ commit }, id) {
      commit("setAnswered", id);
    },
    addImage({ commit }, image) {
      commit("addImage", image);
    },
  },
  modules: {
    leaderboard,
    apitest,
    analysis,
  },
});
