import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

require("../assets/main.scss");

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/analysen",
    name: "analysen",
    component: () => import("../views/AnalysisExplainationView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/SettingsView.vue"),
  },
  {
    path: "/upload",
    name: "upload",
    component: () => import("../views/UploadView.vue"),
  },
  {
    path: "/game",
    name: "game",
    component: () => import("../views/GameView.vue"),
  },
  {
    path: "/game/:fakedetect/:diffshow",
    name: "gameset",
    component: () => import("../views/GameView.vue"),
  },
  {
    path: "/endscreen",
    name: "endscreen",
    component: () => import("../views/EndView.vue"),
  },
  {
    path: "/uploadanalysis",
    name: "uploadanalysis",
    component: () => import("../views/UploadAnalysisView.vue"),
  },
  //beschreibung der analyse
  {
    path: "/analysen/ela",
    name: "Ela",
    component: () =>
      import("../components/analysenbeschreibungen/ElaBeschreibung.vue"),
  },
  {
    path: "/analysen/nla",
    name: "Nla",
    component: () =>
      import("../components/analysenbeschreibungen/NlaBeschreibung.vue"),
  },
  {
    path: "/analysen/strings",
    name: "Strings",
    component: () =>
      import("../components/analysenbeschreibungen/Stringsbeschreibung.vue"),
  },
  {
    path: "/analysen/bildsubtraktion",
    name: "Bildsubtraktion",
    component: () =>
      import(
        "../components/analysenbeschreibungen/SubtraktionsBeschreibung.vue"
      ),
  },
  {
    path: "/analysen/lugra",
    name: "Lugra",
    component: () =>
      import("../components/analysenbeschreibungen/LumaBeschreibung.vue"),
  },
  {
    path: "/analysen/quantisierungstabellen",
    name: "Quantisierungstabellen",
    component: () =>
      import("../components/analysenbeschreibungen/QuantBeschreibung.vue"),
  },
  {
    path: "/analysen/metadata",
    name: "Metadata",
    component: () =>
      import("../components/analysenbeschreibungen/MetadataBeschreibung.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
