import axios from "axios";

const analysis = {
  namespaced: true,
  state: {
    imageFolderPath: "/images/",
    uploadedImageName: null,
    resultPath: null,
  },
  getters: {
    getUploadedImageName(state) {
      return state.uploadedImageName;
    },
    getResultPath(state) {
      return state.resultPath;
    },
  },
  mutations: {
    setUploadedImageName(state, payload) {
      state.uploadedImageName = payload.uploadedImageName;
    },
  },
  actions: {
    async loadImageFromServer({ rootGetters }, payload) {
      try {
        const response = await axios.get(
          rootGetters.getHostURL + "/get_image/" + payload.path,
          {
            responseType: "arraybuffer",
          }
        );
        const blob = new Blob([response.data], { type: "image/png" });
        return URL.createObjectURL(blob);
      } catch (error) {
        console.error(error);
      }
    },
    async uploadImage({ commit, rootGetters }, payload) {
      try {
        const formData = new FormData();

        if (payload.isGame) {
          const imageUrl = require(`../../assets/image_library/${payload.image}`);
          const response = await fetch(imageUrl);
          const blob = await response.blob();
          const file = new File([blob], payload.image);
          formData.append("file", file);
        } else {
          formData.append("file", payload.image);
        }

        const response = await axios.post(
          rootGetters.getHostURL + "/uploader",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        commit("setUploadedImageName", {
          uploadedImageName: response.data.path,
        });

        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async elaAnalysis({ rootGetters, dispatch }, payload) {
      if (payload.isGame) {
        const uploadData = {
          image: payload.gameImage,
          isGame: true,
        };
        await dispatch("uploadImage", uploadData);
      }

      const path = await this.getters["analysis/getUploadedImageName"];

      try {
        const response = await axios.post(
          rootGetters.getHostURL + "/analysis",
          {
            path: path,
            analysisType: "ela",
            analysisParams: {
              quality: payload.quality,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response.data.analyzedPath;
      } catch (error) {
        console.error(error);
      }
    },
    async stringsAnalysis({ rootGetters, dispatch }, payload) {
      if (payload.isGame) {
        const uploadData = {
          image: payload.gameImage,
          isGame: true,
        };
        await dispatch("uploadImage", uploadData);
      }

      const path = await this.getters["analysis/getUploadedImageName"];
      try {
        const response = await axios.post(
          rootGetters.getHostURL + "/analysis",
          {
            path: path,
            analysisType: "strings",
            analysisParams: {
              n: 10,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response.data.stringResult;
      } catch (error) {
        console.error(error);
      }
    },
    async metaDataAnalysis({ rootGetters, dispatch }, payload) {
      if (payload.isGame) {
        const uploadData = {
          image: payload.gameImage,
          isGame: true,
        };
        await dispatch("uploadImage", uploadData);
      }
      const path = await this.getters["analysis/getUploadedImageName"];
      try {
        const response = await axios.post(
          rootGetters.getHostURL + "/analysis",
          {
            path: path,
            analysisType: "meta",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response.data.metaResult;
      } catch (error) {
        console.error(error);
      }
    },
    async noiseLevelAnalysis({ rootGetters, dispatch }, payload) {
      if (payload.isGame) {
        const uploadData = {
          image: payload.gameImage,
          isGame: true,
        };
        await dispatch("uploadImage", uploadData);
      }
      const path = await this.getters["analysis/getUploadedImageName"];
      try {
        const response = await axios.post(
          rootGetters.getHostURL + "/analysis",
          {
            path: path,
            analysisType: "noise",
            analysisParams: {
              kernelSize: +payload.kernelSize,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response.data.analyzedPath;
      } catch (error) {
        console.error(error);
      }
    },
    async lumaGradAnalysis({ rootGetters, dispatch }, payload) {
      if (payload.isGame) {
        const uploadData = {
          image: payload.gameImage,
          isGame: true,
        };
        await dispatch("uploadImage", uploadData);
      }
      const path = await this.getters["analysis/getUploadedImageName"];
      try {
        const response = await axios.post(
          rootGetters.getHostURL + "/analysis",
          {
            path: path,
            analysisType: "lumaGrad",
            analysisParams: {
              kernelSize: +payload.kernelSize,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response.data.analyzedPath;
      } catch (error) {
        console.error(error);
      }
    },
    async thumbAnalysis({ rootGetters, dispatch }, payload) {
      if (payload.isGame) {
        const uploadData = {
          image: payload.gameImage,
          isGame: true,
        };
        await dispatch("uploadImage", uploadData);
      }
      const path = await this.getters["analysis/getUploadedImageName"];
      try {
        const response = await axios.post(
          rootGetters.getHostURL + "/analysis",
          {
            path: path,
            analysisType: "thumb",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response.data.analyzedPath;
      } catch (error) {
        console.error(error);
      }
    },
    async quantAnalysis({ rootGetters, dispatch }, payload) {
      if (payload.isGame) {
        const uploadData = {
          image: payload.gameImage,
          isGame: true,
        };
        await dispatch("uploadImage", uploadData);
      }
      const path = await this.getters["analysis/getUploadedImageName"];
      try {
        const response = await axios.post(
          rootGetters.getHostURL + "/analysis",
          {
            path: path,
            analysisType: "quant",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response.data.quantResult;
      } catch (error) {
        console.error(error);
      }
    },
  },
};

export default analysis;
